// Main App.js file which load first, it'll manage entire Application routing with lazy loading
import axios from './utils/axios';
import * as API_END_POINT from './constants/api';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  isLoggedIn,
  selectRedirectAfterLogin,
} from './redux/auth/authSelector';
import {
  selectRoleUpdated,
  selectUserDetails,
} from './redux/user/userSelector';
import { bottomNotification } from './redux/notification/notificationSelector';
import closeIcon from './assets/images/icons/icon-newclose.svg';
import { getUserInfo } from './actions/authActions';
import { AuthGuard } from './guards/AuthGuard';
import { selectUploadFileStatus, selectUploadFileStatusCount, selectUploadFileStatusErrror } from './redux/Brand/Products/productSelectors';
import CommonFileUploadStatusError from './layout/commonFileUploadStatusError';
import { getProductUploadStatus } from './actions/productActions';
import { setRedirectAfterLogin } from './redux/auth/authSlice';
import { generateRandomId } from './actions/commonActions';
import 'react-toastify/dist/ReactToastify.css';
import { isStoreFront } from './helpers/helper';

const Loader = lazy(() => import('./components/Loader'));
const ResetPasswordSuccess = lazy(() =>
  import('./pages/Auth/ResetPassword/ResetPassworeSuccess')
);
const SiteMap = lazy(() => import('./pages/Sitemap'));

// Auth Pages
const SignIn = lazy(() => import('./pages/Auth/SignIn'));
const SignUp = lazy(() => import('./pages/Auth/SignUp'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() =>
  import('./pages/Auth/ResetPassword/ResetPassword')
);
const CreatePassword = lazy(() => import('./pages/Auth/CreatePassword'));
const PersonalizeNotSupport = lazy(() =>
  import('./pages/Onboarding/Personalize/personalizedNotSupport')
);
const ForgotPasswordSent = lazy(() =>
  import('./pages/Auth/ForgotPassword/ForgotPasswordSent')
);

// NoMatch Page
const NoMatch = lazy(() => import('./components/NoMatch'));

// Onboarding Pages
const CreateAccount = lazy(() => import('./pages/Onboarding/CreateAccount'));
const EmailVerification = lazy(() =>
  import('./pages/Onboarding/EmailVerification')
);
const EmailVerificationSuccess = lazy(() =>
  import('./pages/Onboarding/EmailVerificationSuccessfully')
);
const EmailVerificationFailure = lazy(() =>
  import('./pages/Onboarding/EmailVerificationFailure')
);
const Personalize = lazy(() => import('./pages/Onboarding/Personalize'));
const BrandOnBoarding = lazy(() =>
  import('./pages/Onboarding/BrandOnboarding')
);
const RetailerOnBoarding = lazy(() =>
  import('./pages/Onboarding/RetailerOnboarding')
);
const UserInfo = lazy(() => import('./pages/Onboarding/UserInfo'));
// Brand Portal Pages
const BrandSettingPage = lazy(() => import('./pages/Brand/Settings'));
const BrandOrdersPage = lazy(() => import('./pages/Brand/Orders'));
const BrandProductsPage = lazy(() => import('./pages/Brand/Products'));
const BrandReportTransaction = lazy(() => import('./pages/Brand/Reports/TransactionReports'));
const BrandProductDetails = lazy(() =>
  import('./pages/Brand/ProductDetails/index')
);
const EditProductDetails = lazy(() =>
  import('./pages/Brand/EditProductDetails')
);
const BrandRetailerPage = lazy(() => import('./pages/Brand/retailers'));
const RetailerProfile = lazy(() =>
  import('./pages/Brand/retailers/RetailerProfile')
);
const BrandRetailersConnected = lazy(() =>
  import('./pages/Brand/Retailer/index')
);
const BrandProductCustomerView = lazy(() =>
  import('./pages/Brand/Products/CustomerView/index')
);
const BrandRequestsforAccess = lazy(() =>
  import('./pages/Brand/Retailer/RequestAccess')
);

//Retailer Portal Pages
const RetailerSettingPage = lazy(() =>
  import('./pages/Retailer/Settings/loadable')
);
const RetailerBrandListPage = lazy(() =>
  import('./pages/Retailer/Brand/loadable')
);
const RetailerStoreListPage = lazy(() =>
  import('./pages/Retailer/Store/index')
);
const RetailerBrandSinglePage = lazy(() =>
  import('./pages/Retailer/Brand/BrandProductPage/Products')
);
const RetailerBrandSingleProductDetailPage = lazy(() =>
  import('./pages/Retailer/Products/ProductDetailsPage/ProductDetails')
);

const RetailerBrandProductsPage = lazy(() =>
  import('./pages/Retailer/Products/loadable')
);
// const RetailerCart = lazy(() => import('./pages/Retailer/Cart/RetailerCart'));
const RetailerCart = lazy(() => import('./pages/Retailer/Cart'));
const RetailerCartCheckout = lazy(() =>
  import('./pages/Retailer/Cart/Checkout/Loadable')
);

const DashboardPage = lazy(() => import('./pages/Dashboard/Loadable'));

const RetailerOrdersPage = lazy(() => import('./pages/Retailer/Orders/loadable'));
const RetailerDashboard = lazy(() =>
  import('./pages/Retailer/DashBoard/Loadable')
);
const RetailerReportTransaction = lazy(() =>
  import('./pages/Retailer/Reports/loadable')
);

const AdminSignIn = lazy(() => import('./pages/Auth/AdminSignin'));
const AdminDashboard = lazy(() => import('./pages/Admin/DashBoard/Loadable'));


const RetailerChannelsPage = lazy(() =>
  import('./pages/Retailer/Channels/loadable')
);

const RetailerPendingOrdersPage = lazy(() => import('./pages/Retailer/PendingOrders/index'));
const BrandMyProfilePage = lazy(() => import('./pages/Brand/MyProfile'));
const RetailerMyProfilePage = lazy(() => import('./pages/Retailer/MyProfile'));

// Store Pages
const RetailerStoreMart = lazy(() =>
  import('./pages/Store/ProductMart/loadable')
);
const RetailerStoreMartProductDetails = lazy(() =>
  import('./pages/Store/ProductDetails/loadable')
);
const RetailerCustomerInformation = lazy(() =>
  import('./pages/Store/CustomerInformation')
);
const RetailerFrontOrdersPage = lazy(() => import('./pages/Store/ViewOrders'));
const RetailerOrderConfirmationPage = lazy(() =>
  import('./pages/Store/commen/OrderConfirmation')
);
const RetailerStoreMartEmptyPage = lazy(() =>
  import('./pages/Store/commen/MartEmpty')
);
const RetailerStorePaymentDetails = lazy(() =>
  import('./pages/Store/CustomerInformation/PaymentDetails')
);

const StoreModule = React.lazy(() => import('./modules/store.module'));


function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const isLogged = useSelector(isLoggedIn);
  const isRoleUpdated = useSelector(selectRoleUpdated);
  const userDetails = useSelector(selectUserDetails);
  const bottomNotify = useSelector(bottomNotification);
  const redirectAfterLogin = useSelector(selectRedirectAfterLogin);
  const param = useParams();
  const noAuthRoute = ['/reset-password/', '/reset-password/:id', '/forgot-password-sent', '/signup/:id', '/signup/:id/', '/signup/:id/:id', '/signup/:id/:id/', '/', '/login', '/login/', "/forgot-password", "/forgot-password/", "/admin-login/"];

  const configPriorityEnv = () => {
    let src = "https://js.sandbox.prioritypassport.com/";
    if (window.location.hostname.search(".myshopdot.com") > -1) {
      src = "https://js.prioritypassport.com/";
    }
    const script = document.createElement('script');
    script.src = src;
    document.body.appendChild(script);
  };
  useEffect(() => {
    configPriorityEnv();
    const device_id = localStorage.getItem('device_id');
    if (!device_id) {
      const new_device_id = generateRandomId(6);
      localStorage.setItem('device_id', new_device_id);

    }
  }, []);
  // useEffect(() => {
  //   const pathname = window.location.pathname;
  //   if (
  //     pathname.includes('/reset-password/') ||
  //     pathname.includes('/forgot-password-sent')
  //   ) {
  //     return;
  //   }

  //   if (isLogged) {
  //     if (!userDetails?.is_email_verified) {
  //       navigate('verify-email');
  //     } else if (!isRoleUpdated) {
  //       navigate('/personalize');
  //     } else if (userDetails.role && userDetails.role.name === 'retailer') {
  //       navigate('/retailer-onboarding');
  //     } else if (userDetails.role && userDetails.role.name === 'brand') {
  //       navigate('/brand-onboarding');
  //     }
  //   } else {
  //     navigate('/login');
  //   }
  // }, []);

  // added code for checking user refresh
  // const checkUserRefresh = () => {
  //   if (isLogged) {
  //     const response = axios.get(API_END_POINT.USER_REFRESH);

  //     response.then((data) => {
  //       if (!data.data.data[0].is_refreshed) {

  //         const response2 = axios.post(API_END_POINT.USER_REFRESH);
  //         response2.then((data) => {
  //           // Hard refresh the whole page
  //           window.location.reload(true);
  //         });
  //       }
  //     });
  //   }
  // };

  // setInterval(() => { 
  //   checkUserRefresh();
  // }, 300000);


  useEffect(() => {

    if(userDetails?.role?.name == 'brand')
    {
      window.Beacon('init', 'f9566071-8b5c-4495-9940-a1ab4186c834');
    }
    else  if(userDetails?.role?.name == 'retailer')
    {
      window.Beacon('init', '3e238f83-ad22-4f5e-b75b-bed46faece4f');
     }
    else
    {
      window.Beacon('destroy');
    }
   
    return () => {
      window.Beacon('close');
    };

  },[isRoleUpdated,userDetails])

  useEffect(() => {
    const pathname = window.location.pathname;
    if (isLogged) {
      if (isRoleUpdated || userDetails?.role?.name) {
        if (redirectAfterLogin) {
          const urlSegment = redirectAfterLogin.split("/");
          if (userDetails?.role?.name === urlSegment[1]) {
            dispatch(setRedirectAfterLogin(""));
            navigate(redirectAfterLogin);
            return;
          }
        }
        if (
          pathname === '/reset-password/' ||
          pathname === '/forgot-password-sent' ||
          pathname === '/signup' ||
          pathname === '/signup/' ||
          pathname === '/signup/:id' ||
          pathname === '/signup/:id/' ||
          pathname === '/signup/:id/:id' ||
          pathname === '/signup/:id//:id' ||
          pathname === '/' ||
          pathname === '/login' ||
          pathname === '/login/'
        ) {

          if (userDetails?.role?.name === 'retailer') {

            if (Object.keys(userDetails?.sub_user_of).length > 0) {

              navigate('/retailer/dashboard');
              return;
            }
            if (userDetails?.is_email_verified) {

              // if (userDetails?.is_onboarding_completed) {
              //   navigate('/retailer/dashboard');
              //   return;
              // }
              // else {
              //   navigate('/retailer/setting');
              //   return;
              // }

              navigate('/retailer/dashboard');
            } else {
              navigate('/retailer/dashboard');
              // navigate('/retailer/setting/');

              // navigate('/retailer-onboarding');
              return;
            }

          } else {
            if (userDetails?.sub_user_of && Object.keys(userDetails?.sub_user_of).length > 0) {
              if (userDetails?.is_subscription_active) {
                navigate("/dashboard");
                return;
              }
              else if (Object.keys(userDetails?.sub_user_of).length > 0 && userDetails?.sub_user_of[0]?.parentUser?.is_subscription_active) {
                navigate("/dashboard");
                return;
              }
              navigate('/brand/setting');
              return;
            }
            if (
              (userDetails?.is_subscription_active || userDetails?.is_onboarding_completed) ||
              ((userDetails?.brandProfile?.profile) && (userDetails?.brandProfile?.integration) && (userDetails?.brandProfile?.paid) && (userDetails?.brandProfile?.shipping) && (userDetails?.brandProfile?.preference))) {
              navigate("/brand/products");
              return;
            } else if (userDetails?.sub_user_of && Object.keys(userDetails?.sub_user_of).length > 0 && userDetails?.sub_user_of[0]?.parentUser?.is_subscription_active) {
              navigate("/dashboard");
              return;
            }
            navigate('/brand/setting');
            return;


          }
          return;
        }
      }
    } else {

      if (!noAuthRoute.includes(pathname)) {

        // if (pathname.search("signup") >= 0 || pathname.search("reset-password") >= 0 || pathname.search("admin-login") >= 0 || pathname.search("personalized-not-supported") >= 0) {
        //   var inputString = window.location.pathname;
        //   var regex = /\/([^\/]+)$/;

        //   var match = inputString.match(regex);

        //   console.log('match[1]', match);
        //   if ((pathname.search("signup") >= 0 && !match) || (pathname.search("signup") >= 0 && match[1] == "signup")) {
        //     dispatch(setRedirectAfterLogin(pathname))
        //     navigate('/login');
        //   }


        // } else {
        //   dispatch(setRedirectAfterLogin(pathname))
        //   navigate('/login');
        // }
      }
    }
  }, [isLogged]);

  const uploadFileStatusError = useSelector(selectUploadFileStatusErrror);
  const [showModal, setShowModal] = useState((uploadFileStatusError?.successCount || uploadFileStatusError?.rejectCount));
  const uploadFileStatus = useSelector(selectUploadFileStatus);
  const uploadFileStatusCount = useSelector(selectUploadFileStatusCount);
  const { location } = history;

  useEffect(() => {
    let interval;
    if (uploadFileStatus) {
      //adjust time to check uploaded product file status
      // interval = setInterval(() => dispatch(getProductUploadStatus()), (1000 * 60));
      interval = setInterval(() => dispatch(getProductUploadStatus()), 2000);
    }
    return () => clearInterval(interval);

  }, [uploadFileStatus]);
  useEffect(() => {
    setShowModal((uploadFileStatusError?.successCount || uploadFileStatusError?.rejectCount));
  }, [uploadFileStatusError]);

  return (
    <>
      <Suspense fallback={""}>
        {
          isStoreFront() ? (
            <Routes>
              <Route path="/*" element={<StoreModule />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" exact element={<Navigate to="/login" replace />} />
              {/* Auth Routes:::start */}
              <Route path="/loader" element={<Loader />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/admin-login" element={<AdminSignIn />} />

              <Route path="/signup/:referralcode?/:sale_partner?" element={<SignUp />} />
              <Route path="/create-password" element={<CreatePassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/forgot-password-sent"
                element={<ForgotPasswordSent />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-password/:user_id" element={<ResetPassword />} />
              <Route
                path="/reset-password-success"
                element={<ResetPasswordSuccess />}
              />
              {/* Auth Routes:::end */}

              {/* Onboarding Routes:::start */}
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/verify-email" element={AuthGuard(EmailVerification)} />
              <Route
                path="/email-verification-success"
                element={<EmailVerificationSuccess />}
              />
              <Route
                path="/email-verification-failure"
                element={<EmailVerificationFailure />}
              />
              <Route path="/personalize" element={<Personalize />} />
              <Route
                path="/personalized-not-supported/:platform"
                element={<PersonalizeNotSupport />}
              />
              <Route path="/user-info" element={<UserInfo />} />
              {/* Onboarding Routes:::end */}

              {/* Brand Onboarding Portal Routes:::start */}
              <Route path="/brand-onboarding" element={<BrandOnBoarding />} />
              <Route path="/retailer-onboarding" element={<RetailerOnBoarding />} />
              {/* Brand Onboarding Portal Routes:::end */}

              {/* Brand Portal Routes::: start */}
              <Route path="/brand/setting" element={<BrandSettingPage />} />
              <Route
                path="/brand/myprofile"
                element={<BrandMyProfilePage />}
              />
              <Route
                path="/brand/setting/:activeTab"
                element={<BrandSettingPage />}
              />
              <Route path="/brand/orders" element={<BrandOrdersPage />} />
              <Route path="/brand/products" element={<BrandProductsPage />} />
              <Route
                path="/brand/products/product-details/:id"
                element={<BrandProductDetails />}
              />
              <Route
                path="/brand/products/customer-view/:id"
                element={<BrandProductCustomerView />}
              />
              <Route
                path="/brand/edit-product/:id"
                element={<EditProductDetails />}
              />
              <Route path="/brand/retailer" element={<BrandRetailerPage />} />
              <Route
                path="/brand/retailer-profile/:id"
                element={<RetailerProfile />}
              />
              {/* Brand Portal Routes::: end */}
              {/* Brand Retailer Request::: start */}
              <Route
                path="/brand/retailers"
                element={<Navigate to="/brand/retailers/connected" replace />}
              />
              <Route
                path="/brand/retailers/connected"
                element={<BrandRetailersConnected />}
              />

              <Route
                path="/brand/retailers/access"
                element={<BrandRequestsforAccess />}
              />
              <Route
                path="/brand/reports/transaction"
                element={<BrandReportTransaction />}
              />
              {/* Brand Retailer Request::: end */}

              {/* Retailer Portal Routes:::start */}
              <Route path="/retailer/dashboard" element={<RetailerDashboard />} />
              <Route path="/retailer/setting/" element={<RetailerSettingPage />} />
              <Route path="/retailer/brands" element={<RetailerBrandListPage />} />
              <Route path="/retailer/store" element={<RetailerStoreListPage />} />
              <Route
                path="/retailer/brands/single/:id"
                element={<RetailerBrandSinglePage />}
              />
              <Route
                path="/retailer/brand/single-product-details/:id"
                element={<RetailerBrandSingleProductDetailPage />}
              />
              <Route
                path="/retailer/setting/:activeTab"
                element={<RetailerSettingPage />}
              />
              <Route
                path="/retailer/products"
                element={<RetailerBrandProductsPage />}
              />
              <Route path="/retailer/cart" element={<RetailerCart />} />
              <Route path="/retailer/cart/checkout" element={<RetailerCartCheckout />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/retailer/orders" element={<RetailerOrdersPage />} />
              <Route path="/retailer/reports/transaction" element={<RetailerReportTransaction />} />
              <Route path="/retailer/myprofile" element={<RetailerMyProfilePage />} />
              <Route path="/retailer/orders/pendingorders" element={<RetailerPendingOrdersPage />} />


              <Route
                path="/retailer/channels"
                element={<RetailerChannelsPage />}
              />

              {/* Retailer Portal Routes:::end */}

              <Route path="/admin/dashboard" element={<AdminDashboard />} />


              <Route path="/sitemap" element={<SiteMap />} />

              <Route path="*" element={<NoMatch />} />
            </Routes >
          )
        }
        {bottomNotify != null && (
          <div className={`bottom-notify active`}>
            <div className="container">
              <div className="bottom-notify_text">
                <p>{bottomNotify}</p>
              </div>
            </div>
            <div className="bottom-notify-close">
              <img src={closeIcon} alt="close" />
            </div>
          </div>
        )
        }

        <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        {(showModal) && ReactDOM.createPortal(
          <CommonFileUploadStatusError {...{ uploadFileStatusError, setShowModal, uploadFileStatusCount }} />, document.body
        )}
      </Suspense>
    </>
  );
}

export default App;
