import axios from '../utils/axios';
import * as API_END_POINT from '../constants/api';
import { logOut, setLoggedIn, setLoginError, setRegister } from '../redux/auth/authSlice';
import { setRoleUpdated, setUserInfo, setUserExploToken } from '../redux/user/userSlice';
import { toast } from 'react-toastify';
import { SEGMENT_SIGN_IN, SEGMENT_SIGN_UP } from '../helpers/segmentHelper';
import { toUtcFormat } from '../helpers/helper';

export function loginAction(formData) {
  return async (dispatch) => {
    try {
      const userData = { email: formData?.email }
      const userRole = await axios.post(API_END_POINT.SIGN_IN_USER_ROLE, userData);
      const role = userRole?.data?.data?.userRole?.length > 0 ? userRole?.data?.data?.userRole[0]?.Role?.name : '';
      //checking current user is brand or retailer
      if (!role || role == "brand" || role == "retailer" || role == "super_admin") {
        const response = await axios.post(API_END_POINT.SIGN_IN, formData);
        if (response && response.data && response.data.code == 200) {
          const data = response.data.data;
          if (role == "brand") {
            const resp2 = await axios.get(`${API_END_POINT.BRAND_PROFILE}/${data.id}/`);
            data['brandProfile'] = {
              preference: resp2?.data?.data?.brandPreference?.id,
              profile: resp2?.data?.data?.brand_profile?.store_name,
              paid: resp2?.data?.data?.payment_detail?.external_account_id,
              shipping: resp2?.data?.data?.shippingRate?.id,
              integration: resp2?.data?.data?.shop_detail?.is_active,
              integration_shop: resp2?.data?.data?.shop_detail?.shop,
              plan: resp2?.data?.data?.subscription_details?.planInfo?.id,
              active_plan: resp2?.data?.data?.subscription_details
            };
          }
          dispatch(setLoggedIn());
          dispatch(setUserExploToken(data?.explo_token))
          dispatch(setUserInfo(data));

          if (role == "brand" || role == "retailer") {
            analytics.identify(data.id, {
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              date: toUtcFormat(new Date()),
              user_id: data.id,
              sub_user_of: (data?.sub_user_of && data?.sub_user_of?.length > 0) ? data.sub_user_of[0]?.parent_user_id : ''
            });
            analytics.track(SEGMENT_SIGN_IN, {
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              date: toUtcFormat(new Date()),
              user_id: data.id,
              sub_user_of: (data?.sub_user_of && data?.sub_user_of?.length > 0) ? data.sub_user_of[0]?.parent_user_id : ''
            });
          }
          dispatch(setUserInfo(response.data.data));
          if (response.data.data.role) {
            dispatch(setRoleUpdated())
          }
        } else {
          // toast.error('Something went worng');
          setLoginError('Something went wrong');
        }
      } else {
        toast.error("Oops! Incorrect username or password.", {
          autoClose: false,
        });
      }
    } catch (err) {
      let error = null;
      const { response } = err || {};
      const { data } = response || {};
      const { errors } = data || {};
      if (errors && errors.length > 0 && errors[0].password) {
        error = errors[0].password;
        dispatch(setLoginError(error));
      } else if (errors) {
        error = errors;
        // dispatch(setLoginError(error));
        toast.error("Oops! Incorrect username or password.", {
          autoClose: false,
        });
      } else {
        dispatch(setLoginError('Something went wrong'));
      }

      // toast.error(
      //   err &&
      //     err.response &&
      //     err.response.data &&
      //     err.response.data.errors &&
      //     err.response.data.errors.length &&
      //     err.response.data.errors[0] &&
      //     err.response.data.errors[0].password
      //     ? err.response.data.errors[0].password
      //     : err && err.response && err.response.data && err.response.data.errors
      //     ? err.response.data.errors
      //     : 'Something went worng'
      // );
    }
  };
}

export function registerAction(formData) {
  return async (dispatch) => {
    try {
      const response = await axios.post(API_END_POINT.REGISTER, formData);
      if (response && response.data && response.data.code == 201) {
        const data = response.data.data;
        dispatch(setLoggedIn());
        dispatch(setUserExploToken(data?.explo_token))
        dispatch(setUserInfo(data));
        dispatch(setUserInfo(response.data.data));
        dispatch(setRegister());
        analytics.track(SEGMENT_SIGN_UP, {
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          date: toUtcFormat(new Date()),
          user_id: data?.id,
          // referred_by: formData?.sales_referal_code ? formData?.sales_referal_code : '',
          referred_by: data?.referred_by,
          sub_user_of: (data?.sub_user_of && Object.keys(data?.sub_user_of).length > 0) ? data?.sub_user_of[0]?.parent_user_id : ''
        });
      } else {
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}

export function sendVerifyEmailAction(formData, isResend = false) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_END_POINT.VERIFICATION_EMAIL,
        formData
      );
      if (response && response.data && response.data.code == 200) {
        if (isResend) {
          toast.success("Verification email resent! ")
        } else {
          toast.success("Verification link has been sent to your email address.")
        }
      } else {
        toast.error('Something went worng');
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}

export function signOutAction(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(API_END_POINT.LOGOUT, payload.fromData);

      if (response && response.data && response.data.code == 200) {
        payload.history.replace('/logout');
        dispatch(logOut());
      } else {
        toast.error('Something went worng');
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}
export function fetchUserDetailAction() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_END_POINT.USER_DETAILS}`);

      if (response && response.data && response.data.code == 200) {
        dispatch(setUserInfo(response.data.data));
      } else {
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}
export async function getUserInfo() {
  try {
    const response = await axios.get(`${API_END_POINT.USER_DETAILS}`);

    if (response && response.data && response.data.code == 200) {
      return response.data.data;
    } else {
      toast.error('Something went worng', {
        autoClose: false,
      });
    }
  } catch (err) {
    return { is_email_verified: false };
  }
}
